import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-banner',
  templateUrl: './home-banner.component.html',
  styleUrls: ['./home-banner.component.scss']
})
export class HomeBannerComponent implements OnInit {

  defaultImage = '/assets/images/loading.gif';
  slide01 = '/assets/images/allproducts_banner.png';
  slide02 = '/assets/images/oilbanner.png';
  slide03 = '/assets/images/serumbanner.png'

  constructor() { }

  ngOnInit() {
  }

}
