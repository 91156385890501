import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo-card-home',
  templateUrl: './logo-card-home.component.html',
  styleUrls: ['./logo-card-home.component.scss']
})
export class LogoCardHomeComponent implements OnInit {

  constructor() { 
     }


  ngOnInit() {
   
  
}


}
