import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-butter-intro-card',
  templateUrl: './butter-intro-card.component.html',
  styleUrls: ['./butter-intro-card.component.scss']
})
export class ButterIntroCardComponent implements OnInit {

  defaultImage = '/assets/images/loading.gif';
  butterProductImage = '/assets/images/products/butter_product.png';
   
  constructor() { }

  ngOnInit() {
  }

}
