import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-serum-product-page',
  templateUrl: './serum-product-page.component.html',
  styleUrls: ['./serum-product-page.component.scss']
})
export class SerumProductPageComponent implements OnInit {

  defaultImage = '/assets/images/loading.gif';
  serumProductBanner = '/assets/images/serumbanner.png';

  constructor() { }

  ngOnInit() {
  }

}
