import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomePageComponent} from './pages/home-page/home-page.component';
import { OilProductPageComponent} from './pages/oil-product-page/oil-product-page.component';
import { SerumProductPageComponent} from './pages/serum-product-page/serum-product-page.component';
import { ContactPageComponent} from './pages/contact-page/contact-page.component';
import { ShopComponent } from './pages/shop/shop.component';
import { ButterProductPageComponent } from './pages/butter-product-page/butter-product-page.component';
import { ShampooProductPageComponent } from './pages/shampoo-product-page/shampoo-product-page.component';
import {ConditionerProductPageComponent} from './pages/conditioner-product-page/conditioner-product-page.component';
import {SprayProductPageComponent } from './pages/spray-product-page/spray-product-page.component';
 




const routes: Routes = [
  {path: '', component: HomePageComponent},
  {path: 'products/restoring-oil', component: OilProductPageComponent},
  {path: 'products/restoring-serum', component: SerumProductPageComponent},
  {path: 'contact-us', component: ContactPageComponent},
  {path: 'shop', component: ShopComponent},
  {path: 'products/butter-page', component: ButterProductPageComponent},
  {path: 'products/spray-page', component: SprayProductPageComponent},
  {path: 'products/conditioner-page', component: ConditionerProductPageComponent},
  {path: 'products/shampoo-page', component: ShampooProductPageComponent}



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
