
import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit {
  defaultImage = '/assets/images/loading.gif';
  shopBannerImage = '/assets/images/contact.png';


  constructor() { }
  

  ngOnInit() {
    
   
  }

}


