import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spray-intro-card',
  templateUrl: './spray-intro-card.component.html',
  styleUrls: ['./spray-intro-card.component.scss']
})
export class SprayIntroCardComponent implements OnInit {

  defaultImage = '/assets/images/loading.gif';
  sprayProductImage = '/assets/images/products/spray_product.png';

  constructor() { }

  ngOnInit() {
  }

}
