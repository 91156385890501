import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
// import {FindStoreModalService } from './find-store-modal.service';

@Component({
  selector: 'app-find-store-modal',
  templateUrl: './find-store-modal.component.html',
  styleUrls: ['./find-store-modal.component.scss']
})

export class FindStoreModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

export class DialogContentExample {
  constructor(public dialog: MatDialog) {}

  openDialog() {
    const dialogRef = this.dialog.open(DialogContentExampleDialog);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}


export class DialogContentExampleDialog {}
