import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oil-intro-card',
  templateUrl: './oil-intro-card.component.html',
  styleUrls: ['./oil-intro-card.component.scss']
})
export class OilIntroCardComponent implements OnInit {

  defaultImage = '/assets/images/loading.gif';
  oilProductImage = '/assets/images/serum_product.png';

  constructor() { }

  ngOnInit() {
  }

}
