import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spray-product-page',
  templateUrl: './spray-product-page.component.html',
  styleUrls: ['./spray-product-page.component.scss']
})
export class SprayProductPageComponent implements OnInit {
  defaultImage = '/assets/images/loading.gif';
  sprayProductBanner = '/assets/images/allproducts_banner.png';

  constructor() { }

  ngOnInit() {
  }

}
