import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FindStoreModalComponent } from './find-store-modal.component';

@Injectable({
  providedIn: 'root'
})
export class FindStoreModalService {

  constructor(public dialog: MatDialog) { }

  openModal() {
    const dialogRef = 
    this.dialog.open(FindStoreModalComponent,{
      height: '700px',
      width: '700px',
      data: ''

    });
  }


}
