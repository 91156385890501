import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oil-product-page',
  templateUrl: './oil-product-page.component.html',
  styleUrls: ['./oil-product-page.component.scss']
})
export class OilProductPageComponent implements OnInit {
  // bannerData;
  defaultImage = '/assets/images/loading.gif';
  oilProductBanner = '/assets/images/oilbanner.png';

  constructor() { }


  ngOnInit() {
  }

}
