import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conditioner-intro-card',
  templateUrl: './conditioner-intro-card.component.html',
  styleUrls: ['./conditioner-intro-card.component.scss']
})
export class ConditionerIntroCardComponent implements OnInit {

  defaultImage = '/assets/images/loading.gif';
  conditionerProductImage = '/assets/images/products/conditioner_product.png';
  constructor() { }

  ngOnInit() {
  }

}
