import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-butter-product-page',
  templateUrl: './butter-product-page.component.html',
  styleUrls: ['./butter-product-page.component.scss']
})
export class ButterProductPageComponent implements OnInit {

  defaultImage = '/assets/images/loading.gif';
  butterProductBanner = '/assets/images/allproducts_banner.png';

  constructor() { }

  ngOnInit() {
  }

}
