import { Component, OnInit } from '@angular/core';
import { FindStoreModalService } from '../find-store-modal/find-store-modal.service';
import { FindStoreModalComponent } from '../find-store-modal/find-store-modal.component';

@Component({
  selector: 'app-find-store-card',
  templateUrl: './find-store-card.component.html',
  styleUrls: ['./find-store-card.component.scss']
})
export class FindStoreCardComponent implements OnInit {
  defaultImage = '/assets/images/loading.gif';
  backgroundImage = '/assets/images/background1.jpg';

  constructor(private FindStoreModalService: FindStoreModalService) { }

  openModal() {
    this.FindStoreModalService.openModal();
    
  }

  ngOnInit() {
  }

}
