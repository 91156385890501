import { Directive, Component, OnInit, Input} from '@angular/core';


@Directive ({
  selector: '[loadScript]'
})


export class ShopDirective implements OnInit {

  @Input('script') param: any;

  constructor() { }

  ngOnInit() {
    // this.loadScript('../assets/js/shop_page.js');
    let node = document.createElement('script');
    node.src = this.param;
    node.type ='text/javascript';
    node.async = false;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

//   public loadScript(url: string) {
//     const body = <HTMLDivElement> document.body;
//     const script = document.createElement('script');
//     script.innerHTML = '';
//     script.src = url;
//     script.async = false;
//     script.defer = true;
//     body.appendChild(script);
//   }

}


