import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss']
})
export class InfoCardComponent implements OnInit {
  defaultImage = '/assets/images/loading.gif';
  infoImage = '/assets/images/product-info.png';

  constructor() { }

  ngOnInit() {
  }

}
