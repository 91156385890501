import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shampoo-product-page',
  templateUrl: './shampoo-product-page.component.html',
  styleUrls: ['./shampoo-product-page.component.scss']
})
export class ShampooProductPageComponent implements OnInit {
  defaultImage = '/assets/images/loading.gif';
  shampooProductBanner = '/assets/images/allproducts_banner.png';
  
  constructor() { }

  ngOnInit() {
  }

}
