import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BannerComponent } from './ui-kit/banner/banner.component';
import { ProductCardsComponent } from './ui-kit/product-cards/product-cards.component';
import { FindStoreCardComponent } from './ui-kit/find-store-card/find-store-card.component';
import { FooterComponent } from './ui-kit/footer/footer.component';
import { MapComponent } from './ui-kit/map/map.component';
import { ImageWithTextCardComponent } from './ui-kit/image-with-text-card/image-with-text-card.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule} from '@angular/material/card';
import { FindStoreModalComponent } from './ui-kit/find-store-modal/find-store-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { InfoCardComponent } from './ui-kit/info-card/info-card.component';
import { HomeBannerComponent } from './ui-kit/home-banner/home-banner.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { SerumProductPageComponent } from './pages/serum-product-page/serum-product-page.component';
import { OilProductPageComponent } from './pages/oil-product-page/oil-product-page.component';
import { SerumIntroCardComponent } from './ui-kit/serum-intro-card/serum-intro-card.component';
import { OilIntroCardComponent } from './ui-kit/oil-intro-card/oil-intro-card.component';
import { NavBarComponent } from './ui-kit/nav-bar/nav-bar.component';
import { LogoCardHomeComponent } from './ui-kit/logo-card-home/logo-card-home.component';
import { WindowService } from './service/window.service';
import { Client } from 'shopify-buy/index.umd';
import { ShopComponent } from './pages/shop/shop.component';
import { ShopDirective } from './shop.directive';
import { ShopBarComponent } from './ui-kit/shop-bar/shop-bar.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ShampooIntroCardComponent } from './ui-kit/shampoo-intro-card/shampoo-intro-card.component';
import { ButterIntroCardComponent } from './ui-kit/butter-intro-card/butter-intro-card.component';
import { ConditionerIntroCardComponent } from './ui-kit/conditioner-intro-card/conditioner-intro-card.component';
import { SprayIntroCardComponent } from './ui-kit/spray-intro-card/spray-intro-card.component';
import { ShampooProductPageComponent } from './pages/shampoo-product-page/shampoo-product-page.component';
import { ConditionerProductPageComponent } from './pages/conditioner-product-page/conditioner-product-page.component';
import { SprayProductPageComponent } from './pages/spray-product-page/spray-product-page.component';
import { ButterProductPageComponent } from './pages/butter-product-page/butter-product-page.component'; 


@NgModule({
  declarations: [
    AppComponent,
    BannerComponent,
    ProductCardsComponent,
    FindStoreCardComponent,
    FooterComponent,
    MapComponent,
    ImageWithTextCardComponent,
    InfoCardComponent,
    HomeBannerComponent,
    HomePageComponent,
    ContactPageComponent,
    SerumProductPageComponent,
    OilProductPageComponent,
    SerumIntroCardComponent,
    OilIntroCardComponent,
    FindStoreModalComponent,
    NavBarComponent,
    LogoCardHomeComponent,
    ShopComponent,
    ShopDirective,
    ShopBarComponent,
    ShampooIntroCardComponent,
    ButterIntroCardComponent,
    ConditionerIntroCardComponent,
    SprayIntroCardComponent,
    ShampooProductPageComponent,
    ConditionerProductPageComponent,
    SprayProductPageComponent,
    ButterProductPageComponent
   
  ],
  entryComponents: [FindStoreModalComponent],
 

  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatDialogModule,
    LazyLoadImageModule 
    
  ],

  providers: [ WindowService ],
  bootstrap: [AppComponent]
})
export class AppModule { }
