declare var require : any;
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
// import { WindowService } from 'src/app/service/window.service';


@Component({
  selector: 'app-product-cards',
  templateUrl: './product-cards.component.html',
  styleUrls: ['./product-cards.component.scss']
})
export class ProductCardsComponent implements OnInit {
  defaultImage = '/assets/images/loading.gif';
  oilImage = '/assets/images/products/oil.png';
  serumImage = '/assets/images/products/serum.png';
  shampooImage = '/assets/images/products/shampoo.png';
  conditionerImage = '/assets/images/products/conditioner.png';
  hairButterImage = '/assets/images/products/hairbutter.png';
  hairSprayImage = '/assets/images/products/hairspray.png';
  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(1, 1)
  });
  }

}
