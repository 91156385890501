import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-serum-intro-card',
  templateUrl: './serum-intro-card.component.html',
  styleUrls: ['./serum-intro-card.component.scss']
})
export class SerumIntroCardComponent implements OnInit {
  defaultImage = '/assets/images/loading.gif';
  serumImage = '/assets/images/products/serum_product.png';

  constructor() { }

  ngOnInit() {
  }

  
}
