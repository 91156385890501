import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shampoo-intro-card',
  templateUrl: './shampoo-intro-card.component.html',
  styleUrls: ['./shampoo-intro-card.component.scss']
})
export class ShampooIntroCardComponent implements OnInit {

  defaultImage = '/assets/images/loading.gif';
  shampooProductImage = '/assets/images/products/shampoo_product.png';
  
  constructor() { }

  ngOnInit() {
  }

}
