import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
  defaultImage = '/assets/images/loading.gif';
  contactBannerImage = '/assets/images/contact.png';

  constructor() { }

  ngOnInit() {


  }

}
